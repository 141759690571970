/* Import Roboto Slab for Greek gonts */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --primary: #FADBC7;
  /*--primary: hsl(202, 15%, 90%); /* white */
  --secondary: #473d72;
  --light: white;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: var(--primary);
  color: var(--light);
}

body {
  color: var(--secondary);
  background-color: var(--primary);
  padding: 1rem;
  font-family: 'Roboto Slab';
}

input {
  width: .75rem;
  height: 1.75rem;
  padding: 0.25rem;
  margin: 0 0.75rem;
  background-color: transparent;
  border: 1px solid var(--secondary);
  border-radius: 50%;
  text-align: center;
}

.menu {
  padding: 2rem 2.25rem 1rem;
  margin: 0 auto;
  max-width: 50rem;
  background-color: var(--light);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

@media (min-width: 660px) {
  .menu {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
}

.menu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  color:var(--secondary) /* pancakes auga klp*/
}

.logo {
  width: 120px;
  margin: 0 auto 2rem;
  display: block;
  grid-column: 1 / 3;
  position: relative;
}

@media (min-width: 660px) {
  .logo {
    margin-bottom: 0;
  }
}

.mains {
  grid-column: 1 / 2;
}

.mains-name {
  margin: 0;
  flex-grow: 1;
}

.mains-description {
  flex: 1 1 100%;
  margin: 0.75rem 0 0;
  color: var(--secondary);
}

.aside {
  grid-column: 2 / 3;
}

.extras {
  color: var(--secondary);
}

.extras + .extras {
  margin-top: 1.5rem;
}

@media (min-width: 660px) {
  .extras + .extras {
    margin-top: 2.5rem;
  }
}

.extras .menu-item {
  margin-bottom: 0.75rem;
}

.extras-heading {
  margin: 0 0 1.5rem;
  border-bottom: 1px solid var(--secondary);
}

.extras-name {
  flex-grow: 1;
}

.total {
  grid-column: 1 / 3;
  margin-top: 2.5rem;
  padding: 0.75rem 0 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  color: var(--secondary);
  background-color: var(--light);
  border-top: 1px solid var(--secondary);
  box-shadow: 0 -0.5rem 0.75rem 0.5rem var(--light);
}

@media (min-width: 660px) {
  .total {
    margin-top: 0;
  }
}

.total-title {
  margin: 0;
}

.total-price {
  font-size: 1.5rem;
}


@media screen and (max-width: 1170px){
  .copyrights {
    margin-bottom: 2rem;
    color: hsl(210, 22%, 49%);
    display: flex;
    text-align: center;
    gap: 1rem 1rem;
    justify-content: center;
  }
  }
  
  @media screen and (min-width: 1170px){
    .copyrights {
      margin-bottom: 2rem;
      color: hsl(210, 22%, 49%);
      display: flex;
      text-align: center;
      gap: 1rem 1rem;
      justify-content: center;
    }
    }

.underline_end {
  width: auto;
  height: 0.15rem;
  background: #fafafd;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.cube-logo {
  object-fit: cover;
  height: auto;
  width: 50px;
  border-radius: 0.25rem;  
}